import React from "react";
import Img from "gatsby-image";

export default props => {
    return (
        <section className="about">
            <div id="about" className="anchor"></div>
            <div className="about__container">
                <h2 className="about__title">{ props.about.frontmatter.title }</h2>

                <div className="about__description">
                    <div className="about__image">
                        <Img fluid={ props.about.frontmatter.aboutImage.childImageSharp.fluid } />
                    </div>

                    <div dangerouslySetInnerHTML={{ __html: props.about.html }} >
                    </div>
                </div>
            </div>
        </section>
    );
};
