import React from "react";
import SEO from "../components/SEO";
import Header from "../components/Header";
import Intro from "../components/Intro";
import About from "../components/About";
import Services from "../components/Services";
import Pricing from "../components/Pricing";
import Contact from "../components/Contact";

import getHomeData from "../queries/getHomeData";
import getAboutData from "../queries/getAboutData";
import getPricingData from "../queries/getPricingData";

export default props => {
    const { data: { frontmatter: home } } = getHomeData();
    const { data: about } = getAboutData();
    const { data: pricing } = getPricingData();

    return (
        <>
        <SEO pageData={ home } />
        <Header headerSubtitle={ home.headerSubtitle } headerTitle={ home.headerTitle } image={ home.backgroundImage.childImageSharp.fluid } />
        <Intro quote={ home.quote } buttonLink={ home.buttonLink } buttonText={ home.buttonText } />
        <Services />
        <About about={ about } />
        <Pricing pricing={ pricing } />
        <Contact />
        </>
    );
};
