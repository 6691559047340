import React from "react";
import Obfuscate from "react-obfuscate";
import Phone from "./svg/phone";
import Mail from "./svg/mail";

import getCompanyInfo from "../queries/getCompanyInfo";

export default props => {

    const { info: { frontmatter: company } } = getCompanyInfo();

    return (
        <section className="contact">
            <div id="contact" className="anchor"></div>
            <div className="contact__container">
                <h2 className="contact__title">Contact</h2>
                <div className="contact__grid">

                    <div className="contact__map">
                        <iframe title={ company.companyAddress } src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2435.6274163692055!2d4.891617815802152!3d52.37717557978751!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c609c7d76cc1af%3A0x12f674fabcd4a2fb!2sSpuistraat%2010%2C%201012%20TS%20Amsterdam!5e0!3m2!1sen!2snl!4v1630527021264!5m2!1sen!2snl" frameborder="0" allowfullscreen="" aria-hidden="false" tabIndex="0"></iframe>
                    </div>

                    <div className="contact__directions">
                        <h3>Contactgegevens</h3>
                        <p>
                            { company.companyName }<br />
                            <br />
                            <b>Bezoekadres</b>:<br />
                            { company.companyAddress }<br />
                            { company.companyZip } { company.companyCity }<br />
                            <br />
                            <b>Postadres:</b><br />
                            Postbus 11232<br />
                            1001 GE Amsterdam<br />
                        </p>
                        <p>
                            <Phone /><Obfuscate tel={ company.companyPhone } /><br />
                            <Mail /><Obfuscate email={ company.companyEmail } />
                        </p>
                        <h3>Het kantoor</h3>
                        <p>
                            Spuistraat 10 Advocaten, gelegen in het oudste stukje van Amsterdam, is makkelijk bereikbaar: op vijf minuten loopafstand van het Centraal Station, met de auto: parkeren in De Kolk.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}
