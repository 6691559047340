import { graphql, useStaticQuery } from "gatsby";

export default () => useStaticQuery(graphql`{
    data: markdownRemark ( frontmatter: { identifier: { eq: "home" } } ) {
        frontmatter {
            seoSubtitle
            seoDescription
            seoKeywords
            headerTitle
            headerSubtitle
            quote
            buttonText
            buttonLink
            backgroundImage {
                childImageSharp {
                    fluid(maxWidth: 1920, quality: 30) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                }
            }
        }
    }
}`);

