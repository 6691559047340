import React from "react";

export default props => (
    <section className="pricing">
        <div className="pricing__container">
            <h2 className="pricing__title">{ props.pricing.frontmatter.title }</h2>
            <div className="pricing__description" dangerouslySetInnerHTML={{ __html: props.pricing.html }} >
            </div>
        </div>
    </section>
);
