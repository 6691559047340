import { graphql, useStaticQuery } from "gatsby";

export default () => useStaticQuery(graphql`{
    data: allMarkdownRemark (filter: { frontmatter: { type: { eq: "service" } } }, sort: {order: ASC, fields: frontmatter___order }) {
        edges {
            node {
                frontmatter {
                    title
                    shortDescription
                    slug
                    backgroundImage {
                        childImageSharp {
                            fluid(maxWidth: 500, maxHeight: 250, quality: 30) {
                                ...GatsbyImageSharpFluid_withWebp_tracedSVG
                            }
                        }
                    }
                }
            }
        }
    }
}`);
