import React from "react";
import Img from "gatsby-image";

export default props => (
    <section className="header">
        <div className="background-image">
            <div className="background-image__content">
                <h1 className="header-title">{ props.headerTitle }</h1>
                <h3 className="header-name">{ props.headerSubtitle }</h3>
                <div className="social">
                    <div className="social__facebook"></div>
                    <div className="social__twitter"></div>
                    <div className="social__linkedin"></div>
                </div>
            </div>
            <div className="background-image__image">
                <Img style={{ height: "100%" }} loading="eager" fluid={ props.image } />
            </div>
        </div>
    </section>
);
