import { graphql, useStaticQuery } from "gatsby";

export default () => useStaticQuery(graphql`{
    data: markdownRemark ( frontmatter: { identifier: { eq: "pricing" } } ) {
        html
        frontmatter {
            title
        }
    }
}`);

