import { graphql, useStaticQuery } from "gatsby";

export default () => useStaticQuery(graphql`{
    data: markdownRemark ( frontmatter: { identifier: { eq: "about" } } ) {
        html
        frontmatter {
            title
            aboutImage {
                childImageSharp {
                    fluid(maxWidth: 600) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                }
            }
        }
    }
}`);

