import React from "react";
import { Link } from "gatsby";

export default props => (
    <section className="intro">
        <div className="intro__container">
            <p className="intro__text">
                { props.quote } 
            </p>
            <div className="intro__action">
                <Link to={ props.buttonLink } title="Contact">{ props.buttonText }</Link>
            </div>
        </div>
    </section>
);
