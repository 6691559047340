import React from "react";

import Service from "./Service";
import getServices from "../queries/getServices";

export default props => {
    const { data: { edges: services } } = getServices();

    return (
        <section className="services">
            <div id="services" className="anchor"></div>
            <div className="services__container">
                <h1 className="services__title">Diensten</h1> 
                <div className="services__cards">
                    { services.map( service => (
                        <Service service={ service.node } key={ service.title } />
                    ))}
                </div> 
            </div>
        </section>
    );
};
