import React from "react";
import Img from "gatsby-image";
import { Link } from "gatsby";

export default props => {
    const { service: { frontmatter: service } } = props;

    return (
        <div class="service">
            <Link className="service__image" to={ service.slug } title={ service.title }>
                <div className="service__image-content">
                    <h3 className="service__title">{ service.title }</h3>
                </div>
                <div className="service__image-image">
                    <Img style={{ height: "100%" }} loading="eager" fluid={ service.backgroundImage.childImageSharp.fluid } />
                </div>
            </Link>
            <div className="service__content">
                <p className="service__description">{ service.shortDescription }</p>
                <div className="service__action">
                    <Link to={ service.slug }>&raquo;&nbsp;Meer informatie</Link>
                </div>
            </div>
        </div>
    );
};
